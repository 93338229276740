import React from "react";
import Carousel from "components/Carousel";
import FeaturesBlurb from "components/FeaturesBlurb";
import styled from "styled-components";
import JourneyWith from "content/JourneyWith.pdf";
import JourneyWithout from "content/JourneyWithout.pdf";
import ReactGA from "react-ga4";
import one from "images/one.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";

const STYLE = styled.div`
  background-color: #e9eced;
  height: 100%;

  .journey-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .journey {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 35rem;
      border-radius: 10px;
      background-color: #eceef0;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      margin: 1rem;
      padding: 1rem;
      .title {
        font-weight: bold;
        font-size: 1.3rem;
      }
      .subtext {
        padding: 1rem;
        height: 8rem;
      }
    }
  }
  .image-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      max-width: 14rem;
      height: 30rem;
    }
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  width: 10rem;
  height: 3rem;
  text-align:center
  font-size: 1.5rem;
  background-color: #2D708E;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background-color: #96a7af;
  }
`;

const openPdfWith = () => {
  window.open(JourneyWith, "_blank");
};

const openPdfWithOut = () => {
  window.open(JourneyWithout, "_blank");
};

export default function LandingPage() {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "LandingPage",
  });
  return (
    <STYLE>
      <Carousel />
      <FeaturesBlurb />
      <div className="journey-wrapper">
        <div className="journey">
          <div className="title">Journey with MSS</div>
          <div className="image-wrapper">
            <img src={one} className="d-block w-100" alt="Slide 1" />
            <img src={two} className="d-block w-100" alt="Slide 1" />
          </div>
          <SubmitButton onClick={openPdfWith}>View</SubmitButton>
        </div>
        <div className="journey">
          <div className="title">Journey without MSS</div>
          <div className="image-wrapper">
            <img src={three} className="d-block w-100" alt="Slide 1" />
            <img src={four} className="d-block w-100" alt="Slide 1" />
          </div>
          <SubmitButton onClick={openPdfWithOut}>View</SubmitButton>
        </div>
      </div>
    </STYLE>
  );
}
