import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import emailjs from "@emailjs/browser";

const ContactContainer = styled.div`
  max-width: 600px;
  margin: 5rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;
const Style = styled.div`
  height: 100vh;
  background-color: #e9eced;
`;

const ContactTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  align-self: flex-start;
  display: flex;
  text-align: left;
`;

const FormLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  min-width: 8rem;
`;

const FormInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 25rem;
  @media only screen and (max-width: 768px) {
    width: 10rem;
  }
`;

const FormTextarea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 25rem;
  @media only screen and (max-width: 768px) {
    width: 10rem;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #18979c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #96a7af;
  }
`;

const ContactPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "ContactPage",
  });
  const handleContactFormSubmit = async (event) => {
    event.persist();
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    try {
      emailjs
        .sendForm("service_vnua6ro", "template_ccc30cs", event.target, {
          publicKey: "vPxbqh7vrG_kgETQn",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            alert("Email sent successfully!");
            event.target.reset();
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email. Please try again later.");
    }
  };

  return (
    <Style>
      <ContactContainer>
        <ContactTitle>Contact Us</ContactTitle>
        <ContactForm onSubmit={handleContactFormSubmit}>
          <FormGroup>
            <FormLabel htmlFor="from_name">Name</FormLabel>
            <FormInput type="text" id="from_name" name="from_name" required />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <FormInput type="email" id="email" name="email" required />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="message">Message</FormLabel>
            <FormTextarea id="message" name="message" rows="5" required />
          </FormGroup>
          <SubmitButton type="submit">Send Message</SubmitButton>
        </ContactForm>
      </ContactContainer>
    </Style>
  );
};

export default ContactPage;
