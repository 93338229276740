import newFrontiers from "images/new-frontiers.png";
import group from "images/group.png";
import ndrc from "images/ndrc.png";

export const BLOG_POSTS = [
  //   {
  //     image: newFrontiers,
  //     imageTwo: newFrontiers,
  //     title:
  //       "Exciting News: My Surgical Support Selected for EIT Health Digital Medical Devices Summer School!",
  //     body: `We are thrilled to announce that My Surgical Support has been selected to participate in the prestigious EIT Health Digital Medical Devices Summer School! This exciting opportunity marks another significant milestone in our journey to revolutionize pre-surgical education and patient empowerment.
  //         About the EIT Health Digital Medical Devices Summer School
  //         The EIT Health Digital Medical Devices Summer School is a comprehensive, two-week program designed to equip participants with the skills and knowledge needed to develop innovative digital medical devices. Held in two vibrant locations—Esch-sur-Alzette, Luxembourg, and Barcelona, Spain—the program offers a unique blend of in-person sessions and online components, providing a holistic learning experience.
  //         Week 1 in Luxembourg:
  //         Introduction to Digital Medical Devices (DMDs): Understanding what makes a good DMD.
  //         Value for Patients and Healthcare Professionals: Exploring the benefits of DMDs.
  //         Regulation and Ethics: Navigating the regulatory landscape.
  //         Hands-On Workshops: Engaging in patient co-creation and prototyping activities.
  //         Week 2 in Spain:
  //         Entrepreneurship and Stakeholder Alignment: Learning from successful entrepreneurs.
  //         Financing and Reimbursement: Understanding financial strategies and market access.
  //         Co-Creation Teamwork: Developing business models, conducting competitor analyses, and pitching.
  //         Networking: Visiting stakeholders and expanding professional connections.
  //         What This Means for Us
  //         Being selected for this prestigious program underscores the potential of My Surgical Support and our commitment to innovation in the healthcare sector. Here’s what we aim to achieve during the summer school:
  //         Enhance Our Technical Expertise: Gain in-depth knowledge of the technical requirements for developing digital medical devices.
  //         Expand Our Network: Connect with industry experts, healthcare professionals, and fellow innovators from across Europe.
  //         Develop Practical Skills: Engage in hands-on workshops to refine our product development processes.
  //         Master Regulatory Processes: Navigate the complex regulatory environment to ensure compliance and safety.
  //         Strengthen Our Business Model: Learn from experienced entrepreneurs and develop robust business strategies.
  //         Looking Ahead
  //         Participating in the EIT Health Digital Medical Devices Summer School will provide us with invaluable insights and skills that will propel My Surgical Support to new heights. This experience will help us refine our app, ensuring it meets the highest standards of quality and effectiveness as we prepare for pilot testing and market launch.
  //         We are incredibly grateful for this opportunity and the support from EIT Health. Stay tuned for updates on our journey and progress as we move through this transformative program!
  //         `,
  //   },
  //   {
  //     image: ndrc,
  //     title:
  //       "Thrilled to Announce: My Surgical Support Selected for NDRC Pre-Accelerator Program!",
  //     body: `We are excited to share that My Surgical Support has been selected to participate in the prestigious NDRC Pre-Accelerator program! This incredible opportunity marks a significant milestone in our journey to revolutionize pre-surgical education and patient empowerment.
  //         About the NDRC Pre-Accelerator
  //         The NDRC Pre-Accelerator is a highly competitive six-week program designed to help early-stage tech startups validate their ideas and prepare for future growth. The program focuses on key areas such as market validation, customer development, pitching, product development, and pathways to funding. Each cohort benefits from:
  //         World-Class Mentorship: Guidance from entrepreneurs who have successfully built, scaled, and exited startups.
  //         Workshops and Breakout Sessions: Access to on-demand content and peer support to tackle common startup challenges.
  //         Showcase Day: An opportunity to pitch to investors and industry stakeholders, showcasing our progress and potential.
  //         What This Means for Us
  //         Participating in the NDRC Pre-Accelerator will provide us with invaluable resources and support as we refine My Surgical Support. Here’s what we aim to achieve during the program:
  //         Market Validation: We will work on validating our idea and testing it in the market, gathering feedback from customers, mentors, and investors.
  //         Product Development: Our focus will be on building and enhancing features that provide real value to our users.
  //         Pitch Preparation: Learning how to craft and deliver compelling pitches to attract future funding and partnerships.
  //         Funding Pathways: Understanding different funding options and preparing to secure investment for our next growth phase.
  //         The Road Ahead
  //         Our selection for the NDRC Pre-Accelerator underscores the potential impact of My Surgical Support. Over the next six weeks, we will engage in intensive learning, networking, and development activities, all aimed at ensuring our app is ready for pilot testing and market launch. This experience will equip us with the tools and insights needed to succeed and make a meaningful difference in the lives of patients preparing for surgery.
  //         We are incredibly grateful for this opportunity and the support from the NDRC team. Stay tuned for updates on our journey and progress as we move through this transformative program!
  //         Thank you for being part of our journey! `,
  //   },
  {
    image: newFrontiers,
    title:
      "Exciting News: My Surgical Support Selected for Phase 3 of New Frontiers!",
    body: `We are thrilled to announce that My Surgical Support has been selected to enter Phase 3 of the New Frontiers program! This significant milestone marks another three months of intensive support and guidance as we focus on refining our app and preparing it for pilot testing in October. During this phase, our primary objectives include finalizing the Minimum Viable Product (MVP), enhancing the app’s features and functionalities, and continuing to develop and format educational content. We will also strengthen our partnerships and explore new funding opportunities to ensure a successful launch. This exciting progress brings us closer to revolutionizing the pre-surgical journey for patients, making it a safer, more efficient, and empowering process. Thank you for your continued support as we move forward on this transformative journey! `,
  },
  {
    image: group,
    title:
      "A Unified Journey Through Phase 2: Deepening Our Market Understanding",
    body: `Embarking on Phase 2 of the New Frontiers program was a pivotal moment for us. This phase signified a deeper dive into market research and product validation, a journey we undertook together with a singular vision: to ensure our healthcare app could meet its critical mission.From the outset, our approach was collaborative and hands-on, engaging directly with those at the heart of our app's purpose. On our very first day, we undertook the task of creating our market flower, a visual tool that helped us map out the ecosystem surrounding our app. This exercise was more than just an analysis; it was a revelation, guiding us to pinpoint precisely where our solution fit within the healthcare sector. The question of whether our app was a 'vitamin' or a 'pain-killer' sparked thoughtful discussion, especially for Aoife, whose background in healthcare lent a unique perspective to this metaphor. Understanding this distinction was crucial, shaping our approach to development and messaging. Throughout this phase, we engaged in comprehensive interviews with a broad spectrum of
individuals, from patients to surgeons, and dialogues with medical
device companies on an R&D basis. These conversations were
enlightening, offering us a direct line to the needs, frustrations,
and expectations of those we aimed to serve. This hands-on market
research was complemented by the invaluable resources provided by the
New Frontiers program, including access to the Market Research Centre
in Enterprise Ireland. Here, we discovered the expansive potential of
our app within the digital health world, insights that would steer our
path forward. The program also afforded us the opportunity to learn
from experts through a series of talks focused on messaging,
networking, and market intelligence. Each session was a building
block, further solidifying our understanding and approach to taking
our solution to market. The diversity of knowledge and perspectives we
were exposed to was not just informative but transformative, reshaping
our strategy and refining our goals. As the two months progressed, our
community within the New Frontiers program became an invaluable source
of support and inspiration. Meeting fellow entrepreneurs from the
Galway and Mayo groups introduced us to a network of innovative
thinkers, all embarked on their unique journeys. The camaraderie and
collective wisdom of this group provided both motivation and insight,
enriching our experience and broadening our outlook. The culmination
of these intense and rewarding two months was our presentation of
progress and objectives for the upcoming period. This milestone was
not only a reflection of how far we had come but also a clear outline
of where we were headed. The feedback and encouragement we received
from our peers and mentors were both affirming and invigorating,
propelling us forward with renewed energy and focus. And then, as a
perfect close to this chapter of hard work and breakthroughs, we
celebrated Niamh's hen party. This joyful occasion was a much-needed
respite, allowing us to step back from our entrepreneurial roles and
simply enjoy the moment. It was a reminder of the bonds we've formed
and the personal journeys we're on alongside our professional ones.
The first two months of Phase 2 were a testament to the importance of
thorough market research, the power of community, and the value of
direct engagement with our target audience. As we move forward, these
experiences form the foundation of our continued development, guiding
us towards our goal of transforming healthcare through innovation.`,
  },
  {
    image: newFrontiers,
    title:
      "A Unified Journey Through Phase 2: Deepening Our Market Understanding",
    body: `In the realm of innovation, it’s often the simple conversations that
    spark monumental ideas. This was exactly the case for us, as the seeds
    of our groundbreaking app were sown. It all began with a casual
    exchange between Niamh and Aoife. Niamh had the entrepreneurial spirit
    but was searching for the right direction, while Aoife, driven by her
    experiences in the healthcare sectors of both Ireland and Australia,
    had identified a critical, unmet need: patients were frequently
    unprepared for surgery. This wasn't just a minor oversight; it was a
    significant gap that could impact patient outcomes, recovery times,
    and overall healthcare efficiency. With the idea taking shape but the
    path ahead unclear, we turned to New Frontiers Phase 1 for guidance.
    This phase, as we learned, was meticulously designed for entrepreneurs
    like us, standing at the crossroads of innovation and realisation.
    Phase 1 of New Frontiers is characterised by its part-time schedule,
    stretching over 8 to 10 weeks, usually during weekends or evenings.
    This structure proved invaluable, allowing us to dive deep into our
    project without stepping away from our current employment. It was the
    perfect introduction to the entrepreneurial ecosystem, designed to
    help us research and test the market potential of our idea in a
    supportive, risk-free environment. Throughout this phase, we were
    exposed to a series of sessions that were nothing short of
    eye-opening. Each meeting, workshop, and discussion was aimed at
    helping us critically evaluate our app idea. We delved into
    understanding our potential customers' needs, a process that
    illuminated the profound impact our app could have on enhancing
    surgical outcomes through better patient preparation. Moreover,
    identifying our value proposition became a central focus, enabling us
    to clearly articulate the unique benefits our solution offered.
    Perhaps one of the most significant aspects of Phase 1 was its role in
    guiding us toward a critical 'go/no-go' decision. This decision-making
    process was not just about whether our idea had commercial viability;
    it was also a profound moment of self-reflection, asking ourselves if
    we were truly ready to commit to the journey ahead. As Phase 1 came to
    a close, we found ourselves at a pivotal moment. The countless hours
    of research, the intense discussions, and the iterative feedback had
    all culminated in a comprehensive understanding of our market and a
    solid validation of our idea. It was then, amidst the anticipation and
    the reflection on our journey thus far, that we received the
    notification that would set the course for our future: we had been
    accepted into Phase 2 of the New Frontiers program. This moment was
    not just a validation of our idea but a recognition of the potential
    impact our solution could have. The acceptance into Phase 2 signified
    a new chapter in our journey, one that promised even greater
    challenges but also the opportunity to further refine our app and
    prepare for its launch. As we stand at this threshold, ready to embark
    on Phase 2, we carry with us the lessons learned and the confidence
    gained from our experiences in Phase 1. Our journey through New
    Frontiers has only just begun, but it is already shaping up to be an
    adventure of growth, discovery, and innovation. To our fellow
    entrepreneurs embarking on this journey, let our story be a testament
    to the importance of starting with a solid foundation. Phase 1 has
    equipped us with the tools, knowledge, and determination needed to
    take on the challenges ahead. As we look forward to the next phase, we
    are reminded that every step of this journey is an opportunity to
    learn, to grow, and to move closer to making our vision a reality.`,
  },
];
