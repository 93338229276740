import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logos from "images/MSS-transparent.png";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const STYLE = styled.div`
  nav {
    min-height: 7em;
  }
  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    font-size: 1.5em;
  }
  @media only screen and (max-width: 768px) {
    .navbar-brand {
      margin-left: 0.5rem;
      img {
        margin-right: 1em;
      }
    }
  }
  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar-toggler {
    margin: 1rem;
  }
  img {
    height: 3em;
    margin-right: 2em;
  }
  .nav-link {
    margin-right: 1em;
    font-size: 1.2rem;
  }

  .navbar {
    position: absolute;
    .navbar-brand {
      color: #18979c !important;
    }
  }
`;
const NavComponent = () => {
  const [expanded, setExpanded] = useState(false);

  const handleCollapse = () => {
    setExpanded(false);
  };
  return (
    <STYLE>
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        className="fixed-top"
        expanded={expanded}
      >
        <Navbar.Brand as={Link} to="/" onClick={handleCollapse}>
          <img src={logos} width="100" height="60" alt="Home" />
          <div>
            <strong> My Surgical Support</strong>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="flex-grow-1 justify-content-end"
        >
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" onClick={handleCollapse}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/features" onClick={handleCollapse}>
              Features
            </Nav.Link>
            <Nav.Link as={Link} to="/blog" onClick={handleCollapse}>
              Blog
            </Nav.Link>
            <Nav.Link as={Link} to="/team" onClick={handleCollapse}>
              Team
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleCollapse}>
              Contact Us
            </Nav.Link>
            <Nav.Link
              href="https://www.linkedin.com/company/my-surgical-support/about/"
              target="_blank"
              className="ml-2"
              onClick={handleCollapse}
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" color="#0762C8" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </STYLE>
  );
};

export default NavComponent;
