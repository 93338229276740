import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faTruckMedical,
  faChartLine,
  faNoteSticky,
  faBookMedical,
} from "@fortawesome/free-solid-svg-icons";

const STYLE = styled.div`
  width: 100%;
  height: 100%;

  .image-wrapper {
    display: flex;
    background-color: #e9eced;
    img {
      max-width: 35rem;
      height: 50rem;
    }
  }
  img {
    width: 10rem;
    height: 10rem;
  }
  .row {
    padding-bottom: 2em;
    background-color: #e9eced;
  }
  .percentage {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .col-lg-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .icon-container {
    display: inline-block;
    animation: none;
    margin-bottom: 1.5rem;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  .col-lg-4:hover {
    .icon-container {
      &.animation {
        animation: bounce 0.5s infinite alternate;
      }
    }
  }
`;
const SubmitButton = styled.button`
padding: 10px 20px;
width: 14rem;
font-size: 1.5rem;
  text-align:center
  font-size: 1.5rem;
  background-color: #2D708E;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 3rem;

  &:hover {
    background-color: #96a7af;
  }
`;

const FeaturesBlurb = () => {
  return (
    <STYLE>
      <div className="row">
        <div className="col-lg-4">
          <div className="icon-container animation">
            <FontAwesomeIcon
              icon={faArrowDown}
              size="4x"
              color="#2D708E"
              //shake
              //transform="shrink-6 left-4"
            />
          </div>
          <h2>Readmissions</h2>
          <p className="percentage">20% reduction</p>
        </div>
        <div className="col-lg-4">
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faTruckMedical}
              size="4x"
              color="#2D708E"
              //beat
              //transform="shrink-6 left-4"
            />
          </div>
          <h2> Complications</h2>
          <p className="percentage">15% reduction</p>
        </div>
        <div className="col-lg-4">
          <div className="icon-container animation">
            <FontAwesomeIcon
              icon={faArrowUp}
              size="4x"
              color="#2D708E"
              //bounce
              //transform="shrink-6 left-4"
            />
          </div>
          <h2>Profits</h2>
          <p className="percentage">Save up to €1000 per patient</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="icon-container ">
            <FontAwesomeIcon
              icon={faChartLine}
              size="4x"
              color="#2D708E"
              //bounce
              //transform="shrink-6 left-4"
            />
          </div>
          <h2>AI Predictive Techology</h2>
          <p className="percentage">Providing personalised journeys</p>
        </div>

        <div className="col-lg-4">
          <div className="icon-container ">
            <FontAwesomeIcon
              icon={faBookMedical}
              size="4x"
              color="#2D708E"
              //bounce
              //transform="shrink-6 left-4"
            />
          </div>
          <h2>Evidence based</h2>
          <p className="percentage">Latest up to date research</p>
        </div>
        <div className="col-lg-4">
          <div className="icon-container ">
            <FontAwesomeIcon
              icon={faNoteSticky}
              size="4x"
              color="#2D708E"
              //bounce
              //transform="shrink-6 left-4"
            />
          </div>
          <h2>Easy to use</h2>
          <p className="percentage">No more paper leaflets</p>
        </div>
      </div>
      <Link to="/features">
        <SubmitButton>More Features</SubmitButton>
      </Link>
    </STYLE>
  );
};

export default FeaturesBlurb;
