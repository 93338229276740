import { useEffect, useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faNoteSticky,
  faStethoscope,
  faChartSimple,
  faCommentMedical,
  faUtensils,
  faPersonWalking,
  faInfo,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";

const STYLE = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;

  img {
    width: 10em;
    height: 100%;
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    .feature {
      width: 24rem;
      height: 22rem;
      margin-left: 2rem;
      margin-right: 2rem;

      .title {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 1rem;
      }
      .details {
        text-align: justify;
      }
    }
  }
`;

const Features = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "ContactPage",
  });
  return (
    <STYLE>
      <div className="features">
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faChartLine} size="4x" color="#2D708E" />
          </div>
          <div className="title">Pain Score Tracker</div>
          <p className="details">
            Patients can log their pain levels using a pain score tracker
            integrated into the app. This feature allows patients to monitor
            their pain levels over time and provides valuable data for
            healthcare providers to adjust pain management strategies
          </p>
        </div>

        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faStethoscope} size="4x" color="#2D708E" />
          </div>
          <div className="title">Paper Leaflets Replacement</div>
          <p className="details">
            Instead of traditional paper leaflets, the app provides digital
            versions of educational materials, surgery guides, and recovery
            instructions. This eco-friendly approach reduces paper waste and
            ensures patients have easy access to up-to-date information at their
            fingertip.
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faChartSimple} size="4x" color="#2D708E" />
          </div>
          <div className="title">AI-Powered Recovery Prediction</div>
          <p className="details">
            Leveraging artificial intelligence algorithms, the app analyzes
            patient data, such as medical history, surgery type, and pain
            scores, to predict recovery trajectories. Based on these
            predictions, personalized feedback and recommendations are provided
            to patients to optimize their recovery journey
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon
              icon={faCommentMedical}
              size="4x"
              color="#2D708E"
            />
          </div>
          <div className="title">Personalised Feedback</div>
          <p className="details">
            The app delivers personalized feedback based on the user's recovery
            progress, pain levels, and adherence to prescribed treatments. This
            feedback may include encouragement, reminders for medication or
            exercises, and suggestions for optimizing recovery outcomes
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faUtensils} size="4x" color="#2D708E" />
          </div>
          <div className="title">Meal Ideas and Nutritional Guidance</div>
          <p className="details">
            Patients receive meal ideas and nutritional guidance tailored to
            their dietary needs and recovery stage. The app provides information
            on foods that promote healing, maintain energy levels, and support
            overall well-being during the recovery process
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faPersonWalking} size="4x" color="#2D708E" />
          </div>
          <div className="title">Pre and Post-Surgery Exercises</div>
          <p className="details">
            The app offers a curated collection of pre and post-surgery
            exercises recommended by healthcare professionals. These exercises
            aim to improve mobility, strength, and flexibility, helping patients
            prepare for surgery and accelerate their recovery afterward
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faInfo} size="4x" color="#2D708E" />
          </div>
          <div className="title">Helpful Articles and Information</div>
          <p className="details">
            Patients have access to a library of articles, videos, and
            educational resources covering various aspects of surgery, recovery,
            pain management, and overall health. This curated content helps
            patients make informed decisions, understand their condition better,
            and stay engaged in their recovery journey
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faHouse} size="4x" color="#2D708E" />
          </div>
          <div className="title">Environment Preparation Checklist</div>
          <p className="details">
            The app includes a checklist to help patients prepare their home
            environment before and after surgery. The checklist covers essential
            items such as setting up a comfortable resting area, ensuring easy
            access to medications and medical supplies, arranging for assistance
            with daily tasks, and creating a safe and supportive environment for
            recovery
          </p>
        </div>
        <div className="feature">
          <div className="icon-container ">
            <FontAwesomeIcon icon={faNoteSticky} size="4x" color="#2D708E" />
          </div>
          <div className="title">Pain Management Resources</div>
          <p className="details">
            We provide various resources and techniques for pain management,
            including medication schedules, relaxation exercises, breathing
            exercises, and mindfulness practices. Patients can access these
            resources to alleviate discomfort and improve their recovery
            experience
          </p>
        </div>
      </div>
    </STYLE>
  );
};

export default Features;
