import "./App.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "Pages/LandingPage";
import Team from "Pages/Team";
import ContactPage from "Pages/Contact";
import Features from "Pages/Features";
import NavComponent from "components/Nav";
import BlogPage from "Pages/Blog";
import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  ReactGA.initialize("G-8KH2CHN010");

  return (
    <>
      <div className="app wrapper">
        <div class="container-fluid">
          <div class="row flex pb-8">
            <NavComponent />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/team" element={<Team />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/features" element={<Features />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
