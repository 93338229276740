import { useEffect, useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import Blurb from "../components/Blurb";

const STYLE = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    width: 10em;
    height: 100%;
  }
  .member-wrapper {
    margin-right: 10rem;
    margin-left: 10rem;
    border: 1px solid red;
    text-align: center;
  }
  .member {
    border-radius: 100px;
    overflow: hidden;

    img {
      width: 15em;
      height: 10rem;
    }
  }
`;

const Team = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "TeamPage",
  });
  return (
    <STYLE>
      <Blurb />
    </STYLE>
  );
};

export default Team;
