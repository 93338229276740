import { useEffect, useState } from "react";
import styled from "styled-components";
import Aoife from "images/aoife.jpg";
import niamh from "images/niamh.jpeg";

const STYLE = styled.div`
  width: 100%;
  img {
    width: 18rem !important;
    max-width: 18rem !important;
    max-height: 20rem !important;
    height: 18rem !important;
  }
  .row {
    background-color: #e9eced;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .email {
    font-weight: bold;
  }
  p {
    text-align: justify;
  }
  @media only screen and (max-width: 768px) {
    .col-lg-4 {
      height: 100% !important;
      margin: 1rem !important;
    }
  }

  .col-lg-4 {
    display: flex;
    width: 35rem;
    height: 45rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f8f9fa;
    margin: 2rem;
    padding: 1rem;

    p {
      min-height: 18rem;
    }
    .top-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 25rem;
    }
  }
`;

const Blurb = () => {
  return (
    <STYLE>
      <div className="row p-4">
        <div className="col-lg-4">
          <div className="top-section">
            <img className="rounded-circle" src={Aoife} alt="ceo" />
            <h2>Aoife Fox</h2>
            <div>CEO / Co-founder </div>
            <div className="email">aoife.fox@mysurgicalsupport.com</div>
          </div>
          <p>
            Aoife, a dedicated nurse who qualified from ATU Athlone, has made a
            profound impact through her work in Ireland and Australia. Known for
            her compassion and commitment to patient care, she's not just about
            healing but also empowering individuals to take control of their
            health. Aoife's approach blends expertise with genuine care,
            inspiring those she treats to actively engage in their health
            journey. Her passion for helping others and encouraging a proactive
            stance on wellness defines her remarkable career in nursing.
          </p>
        </div>
        <div className="col-lg-4">
          <div className="top-section">
            <img className="rounded-circle" src={niamh} alt="ceo" />
            <h2>Niamh Gilligan</h2>
            <div>CTO / Co-founder</div>
            <div className="email">niamh.gilligan@mysurgicalsupport.com</div>
          </div>
          <p>
            Niamh's journey from teaching to software development is driven by
            her deep-seated love for technology, especially her excitement about
            the endless possibilities of AI. While her roots in education have
            shaped her approach, it's her fascination with tech that truly
            defines her career today. Niamh's enthusiasm for the latest
            developments in software and AI isn't just about the technology
            itself but about how it can transform everyday life and open up new
            pathways for innovation. As she delves deeper into the tech world,
            her commitment to leveraging technology to solve complex problems
            and inspire new ways of thinking marks her as a forward-thinking
            developer excited about the future of digital evolution.
          </p>
        </div>
      </div>
    </STYLE>
  );
};

export default Blurb;
