import React from "react";
import styled from "styled-components";
import { BLOG_POSTS } from "blogs/blogs";
import ReactGA from "react-ga4";

const BlogContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const BlogPost = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

const BlogTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 2rem;
`;

const BlogImages = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  max-height: 25rem;
`;

const BlogImage = styled.img`
  border-radius: 8px;
  margin-bottom: 20px;
  max-height: 25rem;
`;

const BlogContent = styled.p`
  font-size: 16px;
  color: #333;
  text-align: justify;
`;

const BlogPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "BlogPage",
  });
  return (
    <BlogContainer>
      {BLOG_POSTS.map((post) => (
        <BlogPost>
          <BlogTitle>{post?.title}</BlogTitle>
          <BlogImages>
            <BlogImage src={post?.image} alt="Blog Post Image" />
          </BlogImages>
          <BlogContent>{post?.body}</BlogContent>
        </BlogPost>
      ))}
    </BlogContainer>
  );
};

export default BlogPage;
