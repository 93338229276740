import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import aoifeVideo from "content/aoife-video.mp4";
import one from "images/one.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";
import appScreenshot from "images/app-screeshot.png";
import { Link } from "react-router-dom";

const STYLE = styled.div`
  margin-bottom: 2em;
  .carousel-item {
    min-height: 35rem;
  }
  .screenshot {
    img {
      max-width: 16rem;
      margin-right: 12rem;
    }
  }

  .text-block {
    display: flex;
    flex-direction: column;
    align-self: start;
    margin-top: 2rem;
    margin-left: 30%;
    transform: translateX(-200%);
    text-align: left;
    opacity: 0;
    animation: slide-in-anim 1.5s ease-out forwards;

    color: white;
    .heading {
      font-size: 3.5rem;
    }
    .subtext {
      text-align: left;
      font-size: 1.5rem;
    }
    .subtext-2 {
      margin-top: 1rem;
      text-align: left;
    }
  }
  @media only screen and (max-width: 768px) {
    .screenshot {
      display: none;
    }
    .text-block {
      padding-top: 3rem;
      margin-left: 1.5rem;
      opacity: 1;
      animation: none;
      transform: none;
      .heading {
        text-align: left;
        font-size: 2.5rem;
      }

      .subtext {
        text-align: left;
        font-size: 1.5rem;
      }
      .subtext-2 {
        margin-top: 1rem;
        text-align: left;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }

  .carousel-item {
    padding: 1.5rem;
    align-items: center;
    background-color: #d9dcde;
    &.center {
      justify-content: center;
    }
  }
  .carousel-container {
    display: flex;
    justify-content: space-around;
  }

  .image-wrapper {
    display: flex;
  }

  .title {
    color: black;
  }
  .item-img {
    height: 15em;
  }
  img {
    width: 10em;
    height: 35em;
  }
  a {
    width: 14rem;
  }
  .item-one {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      width: 10em;
      height: 15em;
      img {
        width: 10em;
        height: 15em;
      }
    }
  }

  @keyframes slide-in-anim {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(-45%);
    }
    75% {
      transform: translateX(-52%);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%);
    }
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  width: 14rem;
  font-size: 1.5rem;
  background-color: #18979c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background-color: #96a7af;
  }
`;
const Carousel = () => {
  const videoRef = useRef(null);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!videoRef.current?.paused) {
        setAutoplay(false); // Pause carousel if video is playing
      } else {
        setAutoplay(true); // Resume autoplay if video is not playing
        // document.getElementById("carouselExampleControls")?.next();
      }
    }, 10000); // Change 10000 to the desired interval in milliseconds

    return () => clearInterval(interval);
  }, []);

  const handleVideoPlay = () => {
    setAutoplay(false); // Pause carousel when video starts playing
  };

  const handleVideoPause = () => {
    setAutoplay(true); // Resume autoplay when video is paused
  };

  return (
    <STYLE>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        // data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div
            className="carousel-item center "
            data-bs-interval={autoplay ? "5000" : "false"}
          >
            {/* Video slide */}
            <video
              ref={videoRef}
              controls
              onPlay={handleVideoPlay}
              onPause={handleVideoPause}
            >
              <source src={aoifeVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <div className="carousel-caption d-none d-md-block">
              <h5>First Slide Video</h5>
              <p>This is a video slide.</p>
            </div> */}
          </div>
          <div
            className="carousel-item center"
            data-bs-interval={autoplay ? "5000" : "false"}
          >
            {/* Other slides */}
            <div className="image-wrapper">
              <img src={one} className="d-block w-100" alt="Slide 1" />
              <img src={two} className="d-block w-100" alt="Slide 1" />
              <img src={three} className="d-block w-100" alt="Slide 1" />
              <img src={four} className="d-block w-100" alt="Slide 1" />
            </div>
            {/* <div className="carousel-caption d-none d-md-block">
              <h5>Second Slide</h5>
              <p>This is the second slide.</p>
            </div> */}
          </div>
          <div
            className="carousel-item background active"
            data-bs-interval={autoplay ? "5000" : "false"}
          >
            <div className="carousel-container">
              <div className="text-block">
                <div className="heading">Revolutionising Healthcare</div>
                <div className="subtext">
                  Reducing readmissions , enhancing recovery.
                </div>
                <div className="subtext-2">
                  Smart preparation , smarter savings.
                </div>
                <Link to="/contact">
                  <SubmitButton>Request a demo</SubmitButton>
                </Link>
              </div>
              <div className="screenshot">
                {" "}
                <img
                  src={appScreenshot}
                  className="d-block w-100"
                  alt="Slide 2"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </STYLE>
  );
};

export default Carousel;
